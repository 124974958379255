<template>
  <div class="container">
    <a-row :gutter="[20, 0]" style="background-color: #f0f2f5">
      <a-col :span="9" style="background: #fff">
        <a-tabs v-model="activeIndex" @change="onChangeType">
          <a-tab-pane :key="2" tab="文生图"></a-tab-pane>
          <a-tab-pane :key="1" tab="图生图"></a-tab-pane>
          <a-tab-pane :key="3" tab="AI改图"></a-tab-pane>
        </a-tabs>
        <div class="left-center">
          <div style="text-align: end">
            <a-button v-if="activeIndex === 3" @click="showComfySetting = true">参数配置</a-button>
          </div>
          <!-- 图片上传 -->
          <div v-if="activeIndex === 1 || activeIndex === 3">
            <div class="img-box">
              <div @click="handleTrack('upload_image')">
                <vod-base-upload
                  :accept-list="'.png,.jpg,.jpeg'"
                  :has-file-list="generateImgList"
                  :params-data="null"
                  :client-id="'bfz-admin'"
                  :get-width="true"
                  :maxW_H="'1024*1024'"
                  :limit-size="1000"
                  maxWidthErrorMsg="图片尺寸超出限制，已等比压缩图片"
                  @handleUrl="e => handleUrl(e, 'generate', 0)"
                  @handlePreview="url => handlePreviewOpen(url, 'single')"
                  @handlePixelError="handlePixelError"
                />
              </div>
              <div class="content">
                <div>
                  <div v-if="!limited">
                    <a-button type="link" @click="openStoreModal('generateImage')">从成品库添加</a-button>
                  </div>
                  <div class="txt">图片像素≤768*1024，大小≤10M</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 主题列表 -->
          <div v-if="activeIndex !== 3">
            <div class="title-box">
              <div class="title">主题</div>
              <div>
                <a-select
                  v-show="activeIndex == 2"
                  v-model="themeSearch.principalIdList"
                  placeholder="品牌"
                  show-search
                  option-filter-prop="children"
                  allowClear
                  style="width: 150px"
                  @change="handleSearchChange"
                >
                  <a-select-option v-for="item in themeBrandList" :key="item.principalId">{{
                    item.principalName
                  }}</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="group_list">
              <a-tag
                class="group_tag"
                :color="!!!themeSearch.subjectGroupIdList ? 'green' : ''"
                @click="handleGroupTagClick()"
                >全部</a-tag
              >
              <a-tag
                class="group_tag"
                :color="themeSearch.subjectGroupIdList == t.id ? 'green' : ''"
                v-for="(t, i) in themeGroupList"
                :key="i"
                @click="handleGroupTagClick(t.id)"
                >{{ t.groupName }}</a-tag
              >
            </div>
            <a-spin :spinning="themeLoading">
              <div :class="['theme-list', 'infinite-list-wrapper', { 'theme-list-pic-height': activeIndex === 2 }]">
                <template v-if="themeList.length > 0">
                  <div
                    class="box theme"
                    :class="themeCheckId === item.id ? 'active-theme' : ''"
                    :style="`background-image: url('${item.renderImage}')`"
                    v-for="item in themeList"
                    :key="item.id"
                    @click="handleThemeChange(item)"
                  >
                    <div class="info-box exclamation">
                      <a-tooltip>
                        <template slot="title">
                          {{ item.subjectDesc || '-' }}
                        </template>
                        <a-icon type="exclamation-circle" class="icon" />
                      </a-tooltip>
                    </div>
                    <div class="bottom">
                      <div class="title">{{ item.subjectTitle || '-' }}</div>
                    </div>
                  </div>
                </template>
                <div class="no-result" v-else>暂无主题</div>
              </div>
            </a-spin>
          </div>
        </div>
        <!-- 生成底部 -->
        <div>
          <div style="position: relative; text-align: right" v-if="activeIndex !== 3">
            <a-button type="link" @click="showTranslate = !showTranslate">翻译</a-button>
            <div class="translate_wrap" v-show="showTranslate">
              <div class="wrap_top">
                <span>中<a-icon type="arrow-right" style="margin: 0 10px" />英</span>
                <a-icon type="close" style="cursor: pointer" @click="showTranslate = false" />
              </div>
              <a-input-search
                style="width: 100%"
                placeholder="请输入需要翻译的单词"
                v-model="translateText"
                :loading="translateLoading"
                @search="handleTranslate"
              ></a-input-search>
              <template v-if="translateResult">
                <a-icon type="arrow-down" style="margin: 10px 0" />
                <div style="display: flex">
                  <div class="trans_res" :title="translateResult">{{ translateResult }}</div>
                  <div
                    style="margin: 0 5px"
                    v-clipboard:copy="translateResult"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                  >
                    <a-button type="link" size="small">拷贝</a-button>
                  </div>
                  <a-button type="link" size="small" @click="handleFillTranslate">填入</a-button>
                </div>
              </template>
            </div>
          </div>
          <a-textarea
            v-if="activeIndex !== 3"
            v-model="generateForm.prompt"
            placeholder="提示词（Prompt），选填"
            :auto-size="{ minRows: 1, maxRows: 15 }"
          />
          <a-row :gutter="[20, 0]" style="padding-top: 10px">
            <a-col :span="12" v-show="activeIndex !== 1">
              生成数量：
              <a-input-number
                v-model="generateForm.batchSize"
                allow-clear
                placeholder="成片数量"
                :min="1"
                :max="4"
                style="width: 60%"
              />
            </a-col>
            <a-col v-if="activeIndex === 2 || activeIndex === 3" :span="12">
              车系：
              <a-select
                v-model="generateForm.carSeriesId"
                :default-active-first-option="false"
                show-search
                :filter-option="filterOption"
                allowClear
                option-label-prop="label"
                placeholder="请选择车系"
                @change="handleCarSeriesChange"
                :loading="carSeriesLoading"
                style="width: 70%"
              >
                <template v-if="activeIndex === 2">
                  <a-select-option
                    v-for="item in carListData"
                    :key="item.carSeriesId"
                    :label="item.carSeriesName"
                    :value="item.carSeriesId"
                  >
                    <div>{{ item.carSeriesName }}</div>
                    <div style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis">
                      {{ item.carLoraPrompt }}
                    </div>
                  </a-select-option>
                </template>
                <template v-if="activeIndex === 3">
                  <a-select-option
                    v-for="item in allCarListData"
                    :key="item.carSeriesId"
                    :label="item.carSeriesName"
                    :value="item.carSeriesId"
                  >
                    <div>{{ item.carSeriesName }}</div>
                    <div style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis">
                      {{ item.carLoraPrompt }}
                    </div>
                  </a-select-option>
                </template>
              </a-select>
            </a-col>
            <a-col style="margin-top: 10px" :span="12" v-if="activeIndex === 2 || activeIndex === 3">
              车身颜色：<a-select
                style="width: 60%"
                placeholder="请选择颜色"
                allowClear
                option-label-prop="label"
                :disabled="!generateForm.carSeriesId"
                v-model="generateForm.carColorKey"
              >
                <a-select-option
                  v-for="(item, index) in seriesColorList"
                  :key="index"
                  :label="item.carColorKey"
                  :value="item.carColorKey"
                  >{{ item.carColorKey }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col style="margin-top: 10px" :span="12" v-if="activeIndex === 2 || activeIndex === 3">
              车身角度：<a-select
                style="width: 60%"
                placeholder="请选择角度"
                allowClear
                option-label-prop="label"
                v-model="generateForm.carAnglePrompt"
              >
                <a-select-option
                  v-for="(item, index) in angleList"
                  :key="index"
                  :value="item.value"
                  :label="item.text"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-col>
          </a-row>
          <a-button
            type="primary"
            size="large"
            style="margin: 10px 0 20px; width: 100%"
            :disabled="generateLoading"
            :loading="generateLoading"
            @click="generateImg"
            >生成</a-button
          >
        </div>
      </a-col>

      <!-- 右侧 -->
      <a-col :span="15">
        <div>
          <div class="task-box-top">
            <!-- 时间筛选 -->
            <a-dropdown
              overlayClassName="dropdown-custom"
              v-model="showDropdown"
              :trigger="['click']"
              @visibleChange="
                (value) => {
                  openTimePanel = value;
                }
              "
            >
              <div class="task-time" @click="(e) => e.preventDefault()">
                {{ searchTimeRangeText }} <a-icon type="down" />
              </div>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-range-picker
                    :value="checkTimeRange"
                    :ranges="timeRange"
                    @change="onTimeChange"
                    :open="openTimePanel"
                  />
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <div class="show-all">
              <a-switch :checked="showAll" @change="changeShowAll"></a-switch>
              <span>展开图片</span>
            </div>
          </div>
          <div style="position: absolute; top: 47px; left: 22px; width: 25px; color: #abadab; font-size: 15px">
            任务
          </div>
          <div style="background: #fff; margin-bottom: -17px; padding: 0 8px 0 45px">
            <a-tabs v-model="taskActiveIndex" @change="onTaskChangeType">
              <a-tab-pane v-for="t in taskTabList" :key="t.value">
                <span slot="tab">
                  {{ t.text }}
                </span>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="task-box task-box-1" style="padding-left: 45px">
            <div class="content">
              <div
                class="task-list-box infinite-list-wrapper"
                v-infinite-x-scroll="loadTaskMore"
                infinite-scroll-disabled="busy"
                infinite-scroll-distance="10"
              >
                <div
                  class="box"
                  :class="taskCheckId === item.id ? 'active-task' : ''"
                  v-for="(item, index) in taskList"
                  :key="index"
                  @click="getOutputListByTaskId(item.id, item.taskStatus, item.batchSize)"
                >
                  <div
                    class="img"
                    :class="
                      item.taskStatus == 'SUCCESS' || item.taskStatus == 'PARTIALLY_SUCCESS'
                        ? 'success-task'
                        : 'no-success-task'
                    "
                    :style="`background-image: url('${item.sourceImage}')`"
                  ></div>
                  <div class="txt" v-if="item.taskStatus == 'IN_THE_LINE'">排队第 {{ item.taskScore }} 位</div>
                  <div class="txt" v-else-if="item.taskStatus == 'RUNNING'">
                    <div><a-icon type="sync" spin /> {{ item.taskProgress }}%</div>
                    <div>
                      {{ item.currentNodeDesc }}
                    </div>
                  </div>
                  <div class="txt" v-else-if="item.taskStatus == 'SUCCESS'">完成</div>
                  <div class="txt" v-else-if="item.taskStatus == 'PARTIALLY_SUCCESS'">部分成功</div>
                  <div class="txt red" v-else-if="item.taskStatus == 'FAILED'">生成失败</div>
                  <a-icon type="exclamation-circle" class="icon exclamation" @click.stop="getTaskDetailById(item.id)" />
                  <a-space class="icon-content">
                    <a-icon
                      v-if="item.taskStatus !== 'RUNNING'"
                      type="delete"
                      class="icon"
                      @click.stop="deleteTask(item.id, 'task')"
                    />
                    <a-icon type="eye" class="icon" @click.stop="handlePreviewOpen(item.sourceImage, 'single')" />
                  </a-space>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tips_row" v-show="errorInfo.showTips">
          <span>{{
            `本次车辆信息还原共计${errorInfo.total}张图片，已成功还原${errorInfo.success}张图片，还原失败的图片请再次提交任务`
          }}</span>
          <a-button type="link" @click="showErrorPic">查看还原失效图片</a-button>
        </div>

        <!-- 操作栏 -->
        <div class="action-row">
          <div class="alert-box">
            <div class="alert-box-content" v-if="imgCheckIdList.length">
              <a-icon type="info-circle" class="info" />
              <a-space>
                <div>
                  已选择
                  <span class="light">{{ imgCheckIdList.length }}</span>
                  项
                </div>
                <div class="light" @click="clearImgCheckIdList">清空</div>
                <a-button
                  v-if="!limited"
                  size="small"
                  style="margin-left: 20px; font-size: 13px"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="jumpEditImage"
                >
                  编辑图片
                </a-button>
                <!-- <a-button
                  v-if="!limited"
                  style="font-size: 13px"
                  size="small"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="handleSaveToPicStore()"
                >
                  存入成品库
                </a-button> -->
                <a-button
                  style="font-size: 13px"
                  size="small"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="batchDownload"
                >
                  下载到本地
                </a-button>
                <a-button
                  style="font-size: 13px"
                  size="small"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="batchDeleteAiImageOutputByIds"
                >
                  批量删除
                </a-button>
                <a-button
                  v-if="!limited"
                  style="font-size: 13px"
                  size="small"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="batchToMyPicStore"
                >
                  存入我的图库
                </a-button>
                <a-button
                  style="font-size: 13px"
                  size="small"
                  v-show="taskActiveIndex !== 1"
                  :disabled="operateLoading || !imgCheckIdList.length"
                  :loading="operateLoading"
                  @click="batchReduction"
                >
                  车辆信息还原
                </a-button>
              </a-space>
            </div>
          </div>
        </div>
        <!-- 任务图片列表 -->
        <a-spin :spinning="loading">
          <div
            :class="['img-list', 'infinite-list-wrapper', taskList.length > 0 || waitList.length > 0 ? '' : 'height']"
            v-infinite-scroll="loadImgMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="50"
          >
            <div class="box" v-for="(item, index) in imgList" :key="item.id">
              <div
                class="img"
                :style="`background-image: url('${item.cover || item.imageUrl}')`"
                @click="handlePreviewOpen(imgList, 'multiple', index)"
              ></div>
              <div class="img-detail" @click="handlePreviewOpen(imgList, 'multiple', index)">
                <div>主题：{{ item.subjectTitle || '-' }}</div>
                <div>{{ item.ctime }}</div>
              </div>
              <a-icon type="exclamation-circle" class="icon exclamation" @click="getTaskDetailById(item.taskId)" />
              <div
                :class="[
                  'checkbox',
                  imgCheckIdList.filter((v) => v.imageUrl === item.imageUrl).length > 0 ? 'show' : '',
                ]"
              >
                <a-checkbox
                  :value="item"
                  :checked="imgCheckIdList.filter((v) => v.imageUrl === item.imageUrl).length > 0"
                  @change="(e) => handleCheckImg(e, item)"
                ></a-checkbox>
              </div>
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>

    <!-- 存入成品库 -->
    <a-modal v-model="showPicStoreModal" @cancel="onCloseModal(1)" width="700px" title="添加品牌、车系、标签">
      <div class="tag-line">
        <div class="tag-title"><span style="color: red">*</span>标签：</div>
        <div class="tag-line" style="flex-wrap: wrap">
          <template v-if="showPicStoreModal">
            <CascaderMenu
              ref="cascaderMenu"
              :option="item"
              v-for="(item, index) in tagTree"
              :key="index"
              @change="(props) => handleTagChange(props, index)"
            />
          </template>
        </div>
      </div>
      <div class="bottom-line">
        <div style="width: 45px px; flex-shrink: 0">已选：</div>
        <div class="tag-list">
          <div class="tags" v-for="value in tagList" :key="value.id">
            <span>{{ value.parentText }}</span
            ><span style="color: #1890ff">{{ value.text }}</span>
            <span>
              <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
            </span>
          </div>
        </div>
      </div>
      <a-form-model ref="form" :model="form" v-bind="{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }">
        <a-row style="margin-top: 10px">
          <a-col :span="11">
            <a-form-model-item label="品牌">
              <a-select
                mode="multiple"
                v-model="form.brand"
                placeholder="请选择品牌"
                show-search
                option-filter-prop="children"
                @change="handleBrandChange"
                :maxTagCount="2"
                allowClear
              >
                <a-select-option v-for="item in brandList" :key="`${item.id},${item.principalName}`">{{
                  item.principalName
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="13">
            <a-form-model-item label="车系">
              <a-select
                mode="multiple"
                v-model="form.type"
                placeholder="请选择车系"
                show-search
                option-filter-prop="children"
                :maxTagCount="2"
                :loading="typeLoading"
                allowClear
              >
                <a-select-option v-for="item in typeList" :key="`${item.id},${item.name}`">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-button @click="onCloseModal(1)">取消</a-button>
        <a-button type="primary" :loading="operateLoading" @click="saveImageLibrary">确认上传</a-button>
      </template>
    </a-modal>

    <!-- 成品库 -->
    <PicStoreModal
      :pic-store-visible="picStoreVisible"
      @closeStoreModal="closeStoreModal"
      @addPic="handleAddPic"
      @previewPressPic="handlePreviewPressPic"
      style="z-index: 1001"
    ></PicStoreModal>

    <!-- 任务信息 -->
    <a-drawer title="任务信息" placement="right" :width="450" :visible="taskVisible" @close="onCloseModal(2)">
      <a-spin :spinning="detailLoading">
        <a-form-model
          ref="taskForm"
          :model="taskForm"
          :labelCol="{ xl: 6, xxl: 5 }"
          :wrapperCol="{ span: 17 }"
          style="padding-bottom: 30px"
        >
          <a-form-model-item label="任务名称" prop="taskName">
            <a-input
              v-model="taskForm.taskName"
              :disabled="taskForm.taskStatus != 'IN_THE_LINE'"
              placeholder="请填写任务名称"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="创建时间" prop="ctime">
            <a-date-picker
              show-time
              placeholder="请选择时间"
              :disabled="taskForm.taskStatus != 'IN_THE_LINE'"
              :value="taskForm.ctime"
              @change="onTaskTimeChange"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="创建人" prop="creator">
            <a-input v-model="taskForm.creator" placeholder="请填写创建人" disabled />
          </a-form-model-item>
          <a-form-model-item label="任务ID" prop="id">
            <a-input v-model="taskForm.id" disabled />
          </a-form-model-item>
          <a-form-model-item
            :label="taskForm.subjectType == 'TEXT2IMAGE_CAR' ? '主题图' : '原图'"
            ref="sourceImage"
            prop="sourceImage"
          >
            <div :class="['img-box', 'default']">
              <vod-base-upload
                :accept-list="'.png,.jpg,.jpeg'"
                :has-file-list="sourceImgList"
                :params-data="null"
                :client-id="'bfz-admin'"
                :get-width="true"
                :maxW_H="'768*1024'"
                showMaxErrorMsg
                :limit-size="3"
                @handleUrl="e => handleUrl(e, 'task', 1)"
                @handlePreview="url => handlePreviewOpen(url, 'single')"
                disabled
              />
            </div>
          </a-form-model-item>
          <a-form-model-item
            label="主题"
            prop="subjectId"
            v-if="taskForm.taskType !== 'VEHICLE_INFORMATION_RESTORATION' && taskForm.taskType !== 'CAR_IMG_BY_IMG'"
          >
            <a-select v-model="taskForm.subjectTitle" placeholder="请选择主题" disabled @change="onChangeSubject">
              <a-select-option v-for="item in themeSelectList" :key="item.id" :prompt="item.prompt">{{
                item.subjectTitle
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="提示词"
            prop="prompt"
            v-if="taskForm.taskType !== 'VEHICLE_INFORMATION_RESTORATION' && taskForm.taskType !== 'CAR_IMG_BY_IMG'"
          >
            <a-textarea
              v-model="taskForm.prompt"
              placeholder="请填写提示词"
              :auto-size="{ minRows: 3, maxRows: 7 }"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="成片数量" prop="batchSize">
            <a-input-number
              v-model="taskForm.batchSize"
              allow-clear
              placeholder="成片数量"
              :min="1"
              :max="4"
              style="width: 100%"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item label="车系" prop="carSeriesName">
            <a-input v-model="taskForm.carSeriesName" disabled />
          </a-form-model-item>
          <a-form-model-item label="车身颜色" prop="carColorKey">
            <a-input v-model="taskForm.carColorKey" disabled />
          </a-form-model-item>
          <a-form-model-item label="车身角度" prop="carAnglePrompt">
            <a-input v-model="taskForm.carAngleText" disabled />
          </a-form-model-item>
          <a-form-model-item v-if="taskForm.taskStatus == 'FAILED'">
            <template slot="label"><span style="color: red">失败原因</span></template>
            {{ taskForm.failedMsg }}
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-space>
          <a-button @click="onCloseModal(2)">取消</a-button>
          <a-button
            type="primary"
            :disabled="taskLoading || !taskForm.subjectId"
            :loading="taskLoading"
            @click="handleRunTaskAgain()"
            v-if="taskForm.taskStatus == 'SUCCESS' || taskForm.taskStatus == 'FAILED'"
            >再次生成</a-button
          >
        </a-space>
      </div>
    </a-drawer>

    <!-- 图片压缩预览 -->
    <preview-modal
      :visible="compressVisible"
      :preview-src="compressSrc"
      title="预览"
      width="40%"
      maxHeight="540px"
      :showFooter="true"
      @cancel="handleCompressCancel"
      @ok="handleCompressOk"
    >
      <template #content>
        <div style="text-align: center; margin-top: 20px">
          图片尺寸超出限制，已等比压缩图片，图片像素为{{ compressData.width }}*{{ compressData.height }}
        </div>
      </template>
    </preview-modal>

    <!-- 还原失败图片弹窗 -->
    <a-modal
      :visible="errorInfo.showModal"
      @cancel="handleErrCancel"
      @ok="handleErrOk"
      width="700px"
      title="查看图片"
      :confirmLoading="errorInfo.loading"
      okText="车辆信息还原"
    >
      <a-spin :spinning="loading">
        <div class="img-list" style="margin-top: 0; min-height: 0; max-height: unset">
          <div class="box" v-for="(item, index) in errorInfo.errImgList" :key="item.id">
            <div
              class="img"
              :style="`background-image: url('${item.cover || item.imageUrl}')`"
              @click="handlePreviewOpen(errorInfo.errImgList, 'multiple', index)"
            ></div>
            <a-icon type="exclamation-circle" class="icon exclamation" @click="getTaskDetailById(item.taskId)" />
            <div
              :class="[
                'checkbox',
                errorInfo.imgCheckIdList.filter((v) => v.imageUrl === item.imageUrl).length > 0 ? 'show' : '',
              ]"
            >
              <a-checkbox
                :value="item"
                :checked="errorInfo.imgCheckIdList.filter((v) => v.imageUrl === item.imageUrl).length > 0"
                @change="(e) => handleCheckErrImg(e, item)"
              ></a-checkbox>
            </div>
          </div>
        </div>
      </a-spin>
    </a-modal>

    <!-- AI改图配置 -->
    <a-modal
      :visible="showComfySetting"
      @cancel="handleCloseComfy"
      @ok="handleComfyOk"
      width="700px"
      title="参数配置"
      okText="确定"
    >
      <a-form-model
        ref="comfyConfigList"
        :model="comfyConfigList"
        :labelCol="{ xl: 6, xxl: 5 }"
        :wrapperCol="{ span: 17 }"
      >
        <a-form-model-item v-for="(item, key, index) in comfyConfigList" :key="index" :label="key" :extra="item.desc">
          <template v-if="filterType(item.type) === 'int'">
            <!-- 特殊处理 seed 下限-1 -->
            <a-input-number
              v-model="item.default"
              :placeholder="`请填写${key}`"
              class="w-100"
              :min="k === 'seed' ? -1 : 0"
              :precision="0"
            />
          </template>
          <template v-if="filterType(item.type) === 'float'">
            <a-input-number
              v-model="item.default"
              :placeholder="`请填写${key}`"
              class="w-100"
              :min="0"
              :precision="2"
            />
          </template>
          <template v-if="filterType(item.type) === 'str'">
            <!-- 特殊处理 prompt-->
            <a-input v-model="item.default" :placeholder="`请填写${key}`" class="w-100" />
          </template>
          <template v-if="filterType(item.type) === 'bool'">
            <a-switch v-model="item.default" />
          </template>
          <span v-show="item.sd_web_option">sd_web_option: {{ item.sd_web_option }}</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import previewModal from '@/components/PreviewModal/previewModal';
import vodBaseUpload from '@/components/VodBaseUpload/vodBaseUpload';
import api from '@/api/xhsAgencyApi';
import PicStoreModal from './components/PicStoreModal';
// import draggable from 'vuedraggable';
import ImageCompressor from 'js-image-compressor';
import ossUploadMixin from '@/components/VodBaseUpload/ossUploadMixin.js';
import CascaderMenu from '@/components/CascaderMenu.vue';
import moment from 'moment';
import { trackRequest } from '@/utils/track';
import themeApi from '@/pages/app/xhsAgency/AiDrawManage/api.js';

export default {
  components: {
    vodBaseUpload,
    PicStoreModal,
    previewModal,
    CascaderMenu,
  },
  mixins: [ossUploadMixin],
  data() {
    return {
      carListData: [],
      allCarListData: [],
      carSeriesName: undefined,
      carSeriesLoading: false,
      brandList: [],
      carSeriesIdList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
        totalPage: 1,
      },
      addTagText: '',
      tagList: [],
      form: {
        brand: undefined,
        type: undefined,
      },
      typeList: [],
      typeLoading: false,
      activeIndex: 2,
      taskActiveIndex: 2, // 任务类型 1-图生图 2-文生图 3-AI改图 4-车辆信息还原
      taskTabList: [
        {
          value: 2,
          text: '文生图',
          taskType: ['TEXT2IMAGE_CAR'],
        },
        {
          value: 1,
          text: '图生图',
          taskType: ['CAR_REPLACE_BACKGROUND'],
        },
        {
          value: 4,
          text: '车辆信息还原',
          taskType: ['VEHICLE_INFORMATION_RESTORATION'],
        },
        {
          value: 3,
          text: 'AI改图',
          taskType: ['CAR_IMG_BY_IMG'],
        },
      ],
      generateForm: {
        sourceImage: '',
        prompt: undefined,
        batchSize: 1,
        carSeriesId: undefined,
        carColorKey: undefined,
        carAnglePrompt: undefined,
      },
      generateImgList: [],
      generateLoading: false,
      themeList: [],
      themeCheckId: undefined,
      themeImg: '',
      stablePrompt: '',
      rules: {
        principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        carSeriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
        freePrompt: [{ required: true, message: '请输入Prompt参数', trigger: 'change' }],
        carPrompt: [{ required: true, message: '请填写参数', trigger: 'blur' }],
      },
      themeLoading: false,
      imgList: [],
      imgCurrent: 1,
      imgTotalPage: 1,
      imgCheckIdList: [],
      current: 0,
      totalPage: 1,
      showPicStoreModal: false,
      picStoreVisible: false,
      operateLoading: false,
      taskList: [],
      waitList: [],
      runAndWaitList: [],
      taskVisible: false,
      taskLoading: false,
      taskForm: {
        sourceImage: undefined,
        subjectId: undefined,
        prompt: undefined,
        batchSize: 1,
        ctime: null,
      },
      themeSelectList: [],
      sourceImgList: [],
      storeType: '',
      taskCurrent: 1,
      taskTotalPage: 1,
      taskBusy: false,
      dragStatus: false,
      compressVisible: false,
      compressSrc: '',
      compressData: { width: 0, height: 0 },
      upload_file_params: {
        business_code: 'bfz',
        need_compress: 1,
        type: 1,
      },
      loading: false,
      showAll: false,
      allTaskLoading: false,
      taskCheckId: '',
      timerInstance: null,
      tagTree: [],
      timeRange: {
        今日: [moment(), moment()],
        近七天: [moment().subtract(6, 'days'), moment()],
        本周: [moment().startOf('week'), moment().endOf('week')],
        本月: [moment().startOf('month'), moment().endOf('month')],
        不限时间: [null, null],
      },
      showDropdown: false,
      openTimePanel: true,
      checkTimeRange: [moment().subtract(6, 'days'), moment()],
      searchTimeRangeText: '本周',
      showTranslate: false,
      translateLoading: false,
      translateText: undefined,
      translateResult: undefined,
      promptLoading: false,
      seriesColorList: [],
      errorInfo: {
        taskId: undefined,
        showTips: false,
        showModal: false,
        total: 0,
        success: 0,
        imgCheckIdList: [],
        errImgList: [],
      },
      angleList: [
        {
          value: 'te xie',
          text: '特写',
        },
        {
          value: 'from back',
          text: '车背面',
        },
        {
          value: 'cesiwu',
          text: '车45度',
        },
        {
          value: 'front view',
          text: '车正面',
        },
        {
          value: 'from side',
          text: '车全侧',
        },
      ],
      searchCarSeriesId: undefined,
      pressFormPicStore: false,
      themeBrandList: [],
      themeSearch: {
        subjectGroupIdList: undefined,
      },
      themeGroupList: [],
      themeLoading: false,
      showComfySetting: false,
      comfyConfigList: {},
      tempComfyConfigList: {},
      detailLoading: false,
      limited: false,
    };
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
    }
  },
  watch: {
    showTranslate(val) {
      if (!val) {
        this.translateLoading = false;
        this.translateText = undefined;
        this.translateResult = undefined;
      }
    },
  },
  methods: {
    moment,
    async init() {
      const { limited } = await this.checkLimit();
      this.limited = limited;
      this.getThemeList();
      this.getTagList();
      this.getBrandList();
      this.getTaskDataList();
      this.getSeriesList();
      this.getThemeGroupList();
      this.getThemeBrandList();
      this.getTypeConfig('CAR_IMG_BY_IMG'); // 获取AI改图参数配置
    },
    batchDownload() {
      let imgIdList = [];
      this.imgCheckIdList.forEach((item) => {
        const name = item.imageUrl.match(/\/([^/]+)$/);
        this.downloadImg(item.imageUrl, name?.[1]);
        imgIdList.push(item.id);
      });
      this.handleTrack('download_local', {
        image_ids: imgIdList,
      });
    },
    async handleCarSeriesChange(carSeriesId) {
      this.generateForm.carColorKey = undefined;
      if (carSeriesId) {
        this.carSeriesLoading = true;
        const { code, data } = await api
          .findByCarSeriesId({ carSeriesId })
          .finally(() => (this.carSeriesLoading = false));
        if (code === 200 && data?.colorPromptSettingArr) {
          this.seriesColorList =
            data.colorPromptSettingArr && data.colorPromptSettingArr.length ? data.colorPromptSettingArr : [];
        } else {
          this.seriesColorList = [];
        }
      } else {
        this.seriesColorList = [];
      }
    },
    downloadImg(imgSrc, name) {
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据'
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgSrc;
    },
    async changeShowAll(checked) {
      this.imgCurrent = 1;
      this.showAll = checked;
      this.loading = true;
      this.imgList = [];
      this.imgCheckIdList = [];
      this.taskCheckId = '';
      this.initErrInfo();
      if (this.showAll) {
        await this.pageAiImageOutput();
      } else {
      }
      this.loading = false;
    },
    getOutputListByTaskId(taskId, status, batchSize) {
      if (status === 'SUCCESS' || status === 'PARTIALLY_SUCCESS') {
        this.imgCheckIdList = [];
        this.taskCheckId = taskId;
        this.loading = true;
        this.showAll = false;
        api
          .getOutputListByTaskIdApi({ taskId })
          .then((res) => {
            if (res.code === 200) {
              this.imgList = res.data.map((val) => {
                val.cover = `${val.imageUrl}?x-oss-process=image/resize,l_288,m_lfit`;
                return val;
              });
              this.initErrInfo();
              if (status === 'PARTIALLY_SUCCESS') {
                this.errorInfo.taskId = taskId;
                this.errorInfo.showTips = true;
                this.errorInfo.total = batchSize;
                this.errorInfo.success = this.imgList.length;
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleCompressCancel() {
      this.compressVisible = false;
      this.compressSrc = undefined;
      this.pressFormPicStore = false;
    },
    async handleCompressOk() {
      if (this.pressFormPicStore) {
        this.handleAddPic(this.compressSrc);
        this.handleCompressCancel();
        this.closeStoreModal();
        return;
      }
      const file = new File([this.compressData], this.compressData.name, {
        type: this.compressData.type,
        lastModified: Date.now(),
      });
      const formData = new FormData();
      formData.append('business_code', this.upload_file_params.business_code);
      formData.append('need_compress', this.upload_file_params.need_compress);
      formData.append('type', this.upload_file_params.type);
      formData.append('file', file);
      await this.normalUploadFile(
        file,
        (data, url) => {
          file.xhr = url;
          this.handleUrl(file, 'generate', 0);
          this.handleCompressCancel();
        },
        formData
      );
    },
    handlePixelError(file) {
      this.compressionImage(file)
        .then((result) => {
          this.compressSrc = URL.createObjectURL(result);
          this.compressData = result;
          this.compressVisible = true;
        })
        .catch(() => {
          this.$message.error('图片大小超出限制, 请重新上传小于6MB的图片');
        });
    },
    compressionImage(file) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new ImageCompressor({
          file: file,
          quality: 0.9,
          maxWidth: 1024,
          maxHeight: 1024,
          redressOrientation: false,
          success: (result) => {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    // 品牌切换
    async handleBrandChange(id) {
      if (id.length) {
        const principalIds =
          id.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        this.typeLoading = true;
        this.typeList = [];
        this.handleRequest('getTypeList', (data) => (this.typeList = data), { principalIds }).finally(
          () => (this.typeLoading = false)
        );
      } else {
        this.typeList = [];
      }
    },
    // 获取品牌
    async getBrandList() {
      await this.handleRequest('getBrandList', (data) => {
        this.brandList = data;
      });
    },
    // 获取标签
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.tagPath,
        key: data.id,
      };
    },
    async getTagList() {
      await this.handleRequest('getTagTree', (data) => {
        this.tagTree = data.map(this.initTagValue);
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 清空品牌车系标签数据
    clearBrandAndType() {
      this.addTagText = '';
      this.tagList.forEach((val) => {
        val.isChoose = false;
      });
      this.form = {
        brand: undefined,
        type: undefined,
      };
    },
    //图片上传
    handleUrl(file, type, uid) {
      let arr = [];
      let url = '';
      if (file) {
        url = file.xhr;
        arr = [
          {
            uid,
            name: type,
            status: 'done',
            url,
            thumbUrl: url,
            editor: true,
          },
        ];
      }

      switch (type) {
        case 'generate':
          this.generateImgList = arr;
          this.generateForm.sourceImage = url;
          break;
        case 'task':
          this.sourceImgList = arr;
          this.taskForm.sourceImage = url;
          this.$refs.sourceImage.onFieldChange();
          break;
      }
    },
    //预览
    handlePreviewOpen(url, state, index) {
      if (state == 'single') {
        const pUrl = url.split('?size')[0];
        const $viewer = this.$viewerApi({
          options: {
            toolbar: true,
          },
          images: [pUrl],
        });
      }
      if (state == 'multiple') {
        const $viewer = this.$viewerApi({
          options: {
            toolbar: true,
            initialViewIndex: index,
            viewed(e) {},
          },
          images: url.map((val) => val.imageUrl),
        });
      }
    },
    //打开模态框
    async handleSaveToPicStore() {
      let imgIdList = [];
      this.imgCheckIdList.map((item) => {
        imgIdList.push(item.id);
      });
      this.handleTrack('save_to_production', {
        image_ids: imgIdList.join(','),
      });

      this.showPicStoreModal = true;
    },

    //关闭模态框
    onCloseModal(type) {
      switch (type) {
        case 1:
          this.showPicStoreModal = false;
          this.clearBrandAndType();
          break;
        case 2:
          this.taskVisible = false;
          this.sourceImgList = [];
          this.taskForm = {
            sourceImage: undefined,
            subjectTitle: undefined,
            prompt: undefined,
            batchSize: 1,
          };
          this.$refs.taskForm.clearValidate();
          break;
        default:
          break;
      }
    },

    //生成
    generateImg() {
      this.handleTrack('click_generate_btn');
      if (!this.generateForm.sourceImage && this.activeIndex != 2) {
        this.$message.warning('请上传图片');
        return;
      }
      if (!this.themeCheckId && this.activeIndex != 3) {
        this.$message.warning('请选择主题');
        return;
      }
      if (!this.generateForm.prompt && this.activeIndex != 3) {
        this.$message.warning('请输入提示词');
        return;
      }
      if (!this.generateForm.batchSize) {
        this.$message.warning('请填写成片数量');
        return;
      }

      let params = {
        ...this.generateForm,
        sourceImage: this.activeIndex != 2 ? this.generateForm.sourceImage : this.themeImg,
        subjectId: this.themeCheckId,
        prompt: this.activeIndex != 2 ? this.stablePrompt + this.generateForm.prompt : this.generateForm.prompt,
      };

      if (this.activeIndex == 3) {
        let subjectConfigParam = {};
        let comfyConfigList = _.cloneDeep(this.comfyConfigList);
        Object.keys(comfyConfigList).forEach((key) => {
          subjectConfigParam[key] = comfyConfigList[key].default;
        });
        params.subjectConfigParam = subjectConfigParam;
        params.prompt = params.subjectConfigParam.base_options?.prompt || undefined;
      }

      if (params.carColorKey) {
        params.carColorPrompt = this.seriesColorList.find(
          (val) => val.carColorKey === params.carColorKey
        )?.carColorPrompt;
      }
      if (this.activeIndex == 2 && params.carSeriesId) {
        params.carSeriesName =
          this.carListData.find((val) => val.carSeriesId === params.carSeriesId)?.carSeriesName || undefined;
      }
      if (this.activeIndex == 3 && params.carSeriesId) {
        params.carSeriesName =
          this.allCarListData.find((val) => val.carSeriesId === params.carSeriesId)?.carSeriesName || undefined;
      }
      this.generateLoading = true;
      api.submitTaskForImg2img(params).then(async (res) => {
        this.generateLoading = false;
        if (res.code == 200) {
          if (this.taskActiveIndex !== this.activeIndex) {
            this.taskActiveIndex = this.activeIndex;
            await this.onTaskChangeType(this.taskActiveIndex);
            if (this.showAll) {
              // 查看全部逻辑
              this.loading = true;
              await this.pageAiImageOutput();
              this.loading = false;
            }
            return;
          }
          this.getTaskDataList();
          if (this.timerInstance) {
            clearInterval(this.timerInstance);
            this.timerInstance = null;
          }
          this.timerInstance = setInterval(() => {
            this.getTaskDataList();
          }, 10000);
        } else {
          this.$message.error(`生成图片失败,${res.message}`);
        }
      });
    },

    //查询任务列表
    runningAndLineTaskList() {
      return new Promise((resolve) => {
        api
          .runningAndLineTaskList({
            creator: localStorage.user_name,
          })
          .then((res) => {
            if (res.code == 200) {
              const currentType = this.taskTabList.find((val) => val.value === this.taskActiveIndex).taskType;
              let _data = res.data;
              // this.taskList = _data.runningTaskList || [];
              // this.waitList = _data.inTheLineTaskList || [];
              const _runAndWaitList = [..._data.inTheLineTaskList.reverse(), ..._data.runningTaskList]; // 总列表

              let _inTheLineTaskList = []; // 当前tab列表
              if (_data.inTheLineTaskList.length) {
                _inTheLineTaskList =
                  _data.inTheLineTaskList.filter((task) => currentType.indexOf(task.taskType) != -1) || [];
              }

              let _runningTaskList = []; // 当前tab列表
              if (_data.runningTaskList.length) {
                _runningTaskList =
                  _data.runningTaskList.filter((task) => currentType.indexOf(task.taskType) != -1) || [];
              }

              this.runAndWaitList = [..._inTheLineTaskList.reverse(), ..._runningTaskList]; // 只展示当前tab列表任务
              // 判断总列表是否还有任务
              if (_runAndWaitList.length > 0) {
                if (!this.timerInstance) {
                  this.timerInstance = setInterval(() => {
                    this.getTaskDataList();
                  }, 10000);
                }
              } else {
                clearInterval(this.timerInstance);
                this.timerInstance = null;
                // 如果展开图片 则刷新图片列表
                if (this.showAll) {
                  this.imgCheckIdList = [];
                  this.imgList = [];
                  this.imgCurrent = 1;
                  this.pageAiImageOutput();
                }
              }
            } else {
              this.$message.error(`查询任务列表失败,${res.message}`);
            }
            resolve(true);
          });
      });
    },

    //查询已成功和已失败的任务列表
    getPageTask(isMore) {
      if (isMore && this.allTaskLoading) return;
      const sTime = `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`;
      const eTime = `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`;
      const startTime = this.checkTimeRange[0] ? encodeURIComponent(sTime) : '';
      const endTime = this.checkTimeRange[1] ? encodeURIComponent(eTime) : '';
      const taskTypeList = this.taskTabList.find((val) => val.value === this.taskActiveIndex).taskType.join(',') || '';
      let params = {
        page: this.taskCurrent,
        size: 10,
        creator: localStorage.user_name,
        taskStatusList: 'SUCCESS,PARTIALLY_SUCCESS,FAILED',
        taskTypeList,
      };
      return new Promise((resolve) => {
        api.getPageTask(params, { startTime, endTime }).then((res) => {
          if (res.code == 200) {
            if (this.taskCurrent === 1) {
              this.taskList = [...this.runAndWaitList, ...res.data.list];
            } else {
              this.taskList = [...this.taskList, ...res.data.list];
            }
            this.taskTotalPage = res.data.totalPage;
            this.taskCurrent = res.data.page;
          } else {
            this.$message.error(`查询任务列表失败,${res.message}`);
          }
          resolve(true);
        });
      });
    },
    async getTaskDataList() {
      if (this.allTaskLoading) return;
      this.allTaskLoading = true;
      try {
        this.taskCurrent = 1;
        await this.runningAndLineTaskList();
        await this.getPageTask();
        this.allTaskLoading = false;
      } catch (error) {
        this.allTaskLoading = false;
      }
    },
    //滚动加载任务
    loadTaskMore() {
      if (this.taskCurrent < this.taskTotalPage) {
        this.taskCurrent++;
        this.getPageTask(true);
      }
    },
    //查询AI产出的图片列表
    pageAiImageOutput() {
      const sTime = `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`;
      const eTime = `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`;
      const startTime = this.checkTimeRange[0] ? encodeURIComponent(sTime) : '';
      const endTime = this.checkTimeRange[1] ? encodeURIComponent(eTime) : '';
      const taskTypeList = this.taskTabList.find((val) => val.value === this.taskActiveIndex).taskType.join(',') || '';

      let params = {
        page: this.imgCurrent,
        size: 16,
        creator: localStorage.user_name,
        taskTypeList,
      };
      return new Promise((resolve) => {
        api.pageAiImageOutput(params, { startTime, endTime }).then((res) => {
          if (res.code == 200) {
            const list = res.data.list.map((val) => {
              val.cover = `${val.imageUrl}?x-oss-process=image/resize,l_288,m_lfit`;
              return val;
            });
            this.imgList = list.concat(this.imgList);
            this.imgTotalPage = res.data.totalPage;
            this.imgCurrent = res.data.page;
          } else {
            this.$message.error(`查询AI产出的图片列表失败,${res.message}`);
          }
          resolve(true);
        });
      });
    },
    loadImgMore() {
      if (this.imgCurrent < this.imgTotalPage) {
        this.imgCurrent++;
        this.pageAiImageOutput();
      }
    },
    //获取任务详情
    getTaskDetailById(id) {
      this.taskVisible = true;
      this.detailLoading = true;
      api
        .getTaskDetailById(id)
        .then((res) => {
          let _data = res.data;
          if (res.code == 200) {
            this.taskForm = {
              ...this.taskForm,
              ..._data,
            };
            this.taskForm.carAngleText = this.getCarAngleText(_data.carAnglePrompt);
            let img = _data.subjectType === 'TEXT2IMAGE_CAR' ? _data.renderImage : _data.sourceImage;
            this.sourceImgList = [
              {
                uid: 2,
                name: 'source',
                status: 'done',
                url: img,
                thumbUrl: img,
                editor: true,
              },
            ];
          } else {
            this.$message.error(`获取任务详情失败,${res.message}`);
          }
        })
        .finally(() => (this.detailLoading = false));
    },
    //保存图片库
    saveImageLibrary() {
      this.operateLoading = true;
      let imageInfoList = [];
      let imgIdList = [];
      this.imgCheckIdList.forEach((item) => {
        let nameList = item.imageUrl.split('/');
        let name = nameList[nameList.length - 1];
        let title = name.split('.')[0];
        imageInfoList.push({
          name: name,
          title: title,
          url: item.imageUrl,
        });
        imgIdList.push(item.id);
      });

      // 处理标签字段
      let tagList = [];
      if (this.tagList?.length) {
        tagList = this.tagList.map((val) => ({
          tagId: val.id,
          tagName: val.text,
        }));
      } else {
        tagList = [];
      }
      if (tagList.length === 0) {
        this.$message.error('请至少选择一个标签');
        this.operateLoading = false;
        return;
      }

      // 处理品牌字段
      let principalInfoList = [];
      if (this.form.brand?.length) {
        principalInfoList = this.form.brand.map((val) => {
          const parm = val.split(',');
          return {
            principalId: parm[0],
            principalName: parm[1],
          };
        });
      } else {
        principalInfoList = [];
      }

      // 处理车系字段
      let vehicleSeriesInfoList = [];
      if (this.form.type?.length) {
        vehicleSeriesInfoList = this.form.type.map((val) => {
          const parm = val.split(',');
          return {
            vehicleSeriesId: parm[0],
            vehicleSeriesName: parm[1],
          };
        });
      } else {
        vehicleSeriesInfoList = [];
      }

      let params = {
        appId: 'bfz',
        filePath: '/bfz/xhs/ai/',
        ossFilePath: '/bfz/xhs/ai/',
        imageInfoList,
        principalInfoList,
        vehicleSeriesInfoList,
        tagList,
      };
      api
        .saveImageLibrary(params)
        .then((res) => {
          this.showPicStoreModal = false;
          this.operateLoading = false;
          if (res.code == 200) {
            this.$message.success('保存图片库成功');
            this.clearImgCheckIdList();
            this.clearBrandAndType();

            this.handleTrack('save_produciton_success', {
              image_ids: imgIdList.join(','),
            });
          } else {
            this.$message.error(`保存图片库失败，${res.message}`);
          }
        })
        .finally(() => (this.operateLoading = false));
    },
    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    handleTagDel(value) {
      if (this.$refs.cascaderMenu[value.compIndex]) {
        this.$refs.cascaderMenu[value.compIndex].delSelect(value.tagPath);
      }
      let tagIndex = -1;
      this.tagList.forEach((ele, index) => {
        if (ele.id == value.id) {
          tagIndex = index;
        }
      });
      this.tagList.splice(tagIndex, 1);
    },
    filterType(type) {
      return type.split("'")[1];
    },
    //选中图片
    handleCheckImg(e, item) {
      if (e.target.checked) {
        this.imgCheckIdList.push(item);
      } else {
        let index = this.imgCheckIdList.indexOf(item);
        this.imgCheckIdList.splice(index, 1);
      }
    },
    //清空选中图片
    clearImgCheckIdList() {
      this.imgCheckIdList = [];
    },
    //批量删除ai作图产出的图片
    batchDeleteAiImageOutputByIds() {
      let ids = [];
      this.imgCheckIdList.map((item) => {
        ids.push(item.id);
      });
      this.handleTrack('delete_image_pitch', {
        image_ids: ids,
      });

      let that = this;
      this.$confirm({
        title: '确定删除选中的图片吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.operateLoading = true;

          api.batchDeleteAiImageOutputByIds(ids).then((res) => {
            that.operateLoading = false;
            if (res.code == 200) {
              that.$message.success('删除成功');
              that.imgCheckIdList = [];
              if (that.taskCheckId) {
                that.getOutputListByTaskId(that.taskCheckId, 'SUCCESS');
              } else if (that.showAll) {
                that.changeShowAll(that.showAll);
              }
            } else {
              that.$message.error(`删除失败，${res.message}`);
            }
          });
        },
      });
    },
    // 批量存入我的图库
    batchToMyPicStore() {
      const params = [];
      let imgIdList = [];
      this.imgCheckIdList.forEach(({ imageUrl }) => {
        let nameList = imageUrl.split('/');
        let name = nameList[nameList.length - 1];
        let fileId = name.split('.')[0];
        params.push({
          fileId,
          url: imageUrl,
        });
        imgIdList.push(fileId);
      });
      this.operateLoading = true;
      api
        .handleBatchToMyPicStore(params)
        .then(({ code, message }) => {
          if (code === 200) {
            this.imgCheckIdList = [];
            this.$message.success('操作成功');
            this.handleTrack('save_to_personal_gallery', {
              image_ids: imgIdList,
            });
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => (this.operateLoading = false));
    },
    // 车辆信息还原
    batchReduction() {
      if (this.imgCheckIdList.length > 1) return this.$message.info('单次任务不得超过1张图片，请重新选择');
      const aiImageOutputIdArr = this.imgCheckIdList.map((img) => img.id);
      api.submitTaskForImg2img({ aiImageOutputIdArr }).then((res) => {
        this.generateLoading = false;
        if (res.code == 200) {
          this.imgCheckIdList = [];
          this.$message.success('操作成功');
          this.onTaskChangeType(4);
        } else {
          this.$message.error(`生成图片失败,${res.message}`);
        }
      });
    },
    // 展示失效图片
    showErrorPic() {
      this.errorInfo.showModal = true;
      this.getErrorImgList();
    },
    // 获取失效图片
    async getErrorImgList() {
      this.loading = true;
      this.errorInfo.errImgList = [];
      const { code, data, message } = await api
        .getFailImgList(this.errorInfo.taskId)
        .finally(() => (this.loading = false));
      if (code === 200) {
        this.errorInfo.errImgList = data;
      } else {
        this.$message.error(message);
      }
    },
    //选中图片
    handleCheckErrImg(e, item) {
      if (e.target.checked) {
        this.errorInfo.imgCheckIdList.push(item);
      } else {
        let index = this.errorInfo.imgCheckIdList.indexOf(item);
        this.errorInfo.imgCheckIdList.splice(index, 1);
      }
    },
    // 失败图片再次还原
    async handleErrOk() {
      if (!this.errorInfo.imgCheckIdList.length) return this.$message.info('请至少选择一张图片');
      const aiImageOutputIdArr = this.errorInfo.imgCheckIdList.map((img) => img.sourceId);
      this.errorInfo.loading = true;
      const { code, message } = await api.submitTaskForImg2img({ aiImageOutputIdArr }).finally(() => {
        this.errorInfo.loading = false;
      });
      if (code === 200) {
        this.handleErrCancel();
        this.getTaskDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleErrCancel() {
      this.errorInfo.showModal = false;
      this.errorInfo.imgCheckIdList = [];
      this.errorInfo.errImgList = [];
    },
    //选择主题
    handleThemeChange(item) {
      this.generateForm.prompt = item.prompt;
      this.themeCheckId = item.id;
      this.stablePrompt = item.stablePrompt || '';
      this.themeImg = item.renderImage;
      this.handleTrack('select_theme');
      // 车系
      this.carListData = [];
      this.generateForm.carSeriesId = undefined;
      this.generateForm.carColorKey = undefined;
      if (item.carSeriesInfoList?.length) {
        item.carSeriesInfoList.forEach((i) => {
          const has = this.allCarListData.find((a) => a.carSeriesId === i.carSeriesId);
          has && this.carListData.push(has);
        });
      }
    },
    //打开图片库弹窗
    openStoreModal(type) {
      this.picStoreVisible = true;
      this.storeType = type;
    },
    //关闭图片库弹窗
    closeStoreModal() {
      this.picStoreVisible = false;
      this.storeType = '';
    },
    //从图片库压缩图片
    handlePreviewPressPic({ imgUrl, width, height }) {
      this.compressVisible = true;
      this.pressFormPicStore = true;
      this.compressSrc = imgUrl;
      this.compressData = { width, height };
    },
    //从图片库添加图片
    handleAddPic(url) {
      if (this.storeType === 'generateImage') {
        this.generateForm.sourceImage = url;
        this.generateImgList = [
          {
            uid: 0,
            name: 'generate',
            status: 'done',
            url,
            thumbUrl: url,
            editor: true,
          },
        ];
      }
      if (this.storeType === 'sourceImage') {
        this.taskForm.sourceImage = url;
        this.sourceImgList = [
          {
            uid: 2,
            name: 'source',
            status: 'done',
            url,
            thumbUrl: url,
            editor: true,
          },
        ];
        this.$refs.sourceImage.onFieldChange();
      }
    },
    //编辑图片
    jumpEditImage() {
      let imageUrlList = [];
      let imgIdList = [];
      this.imgCheckIdList.map((item) => {
        imageUrlList.push(item.imageUrl);
        imgIdList.push(item.id);
      });

      this.handleTrack('edit_image', {
        image_ids: imgIdList.join(','),
      });

      this.operateLoading = true;
      api.saveImageList({ configType: 'imageEdit', data: { imageUrlList } }).then((res) => {
        this.operateLoading = false;
        if (res.code == 200) {
          window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?localId=${res.data}`, '_blank');
        } else {
          this.$message.error(`提交图片数据失败，${res.message}`);
        }
      });
    },
    /**
     * 删除任务
     */
    deleteTask(id, type) {
      if (type == 'task') {
        api.hideTask(id).then((res) => {
          if (res.code == 200) {
            this.$message.success('删除任务成功');
            this.getTaskDataList();
            if (id === this.taskCheckId) {
              this.taskCheckId = '';
              this.imgList = [];
              this.generateForm.carSeriesId = undefined;
              this.carListData = [];
            }
          } else {
            this.$message.error(`删除任务失败，${res.message}`);
          }
        });
      }
      if (type == 'wait') {
        let that = this;
        this.$confirm({
          title: '确定删除该任务吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            api.discardTask(id).then((res) => {
              if (res.code == 200) {
                that.$message.success('删除任务成功');
                that.getTaskDataList();
              } else {
                that.$message.error(`删除任务失败，${res.message}`);
              }
            });
          },
        });
      }
    },
    // 再次生成
    async handleRunTaskAgain() {
      this.taskLoading = true;
      const postData = JSON.parse(JSON.stringify(this.taskForm));
      if (this.taskForm.subjectType == 'TEXT2IMAGE_CAR') {
        // 文生图
        await this.onChangeType(2);
      } else {
        await this.onChangeType(1);
        const url = postData.sourceImage.split('?size')[0];
        this.generateForm.sourceImage = url;
        this.generateImgList = [
          {
            uid: 0,
            name: 'generate',
            status: 'done',
            url,
            thumbUrl: url,
            editor: true,
          },
        ];
      }
      this.taskLoading = false;
      this.onCloseModal(2);
      const item = this.themeList.find((ele) => ele.id == postData.subjectId);
      if (!item?.id) {
        this.$message.error('主题已被删除，无法查询原数据!');
        return;
      }
      this.$message.success('已填充原生成数据');
      this.handleThemeChange(item);
      this.generateForm.carAnglePrompt = postData.carAnglePrompt;
      this.generateForm.carSeriesId = postData.carSeriesId;
      postData.carSeriesId && (await this.handleCarSeriesChange(postData.carSeriesId));
      this.generateForm.carColorKey = postData.carColorKey;
    },
    //批量更新任务的队列顺序
    batchUpdateTaskScore() {
      this.dragStatus = true;
      let ids = [];
      this.waitList.map((item, index) => {
        ids.push({
          id: item.id,
          taskScore: index,
        });
      });
      api.batchUpdateTaskScore(ids).then((res) => {
        this.dragStatus = false;

        if (res.code == 200) {
          this.getTaskDataList();
        } else {
          this.$message.error(`更新任务的队列顺序失败,${res.message}`);
        }
      });
    },
    //修改主题
    onChangeSubject(val, option) {
      this.taskForm.prompt = option.data.attrs.prompt;
    },
    //切换生图类型
    async onChangeType(value) {
      return new Promise(async (resolve) => {
        this.current = 1;
        this.generateImgList = [];
        this.themeCheckId = undefined;
        this.activeIndex = value;
        this.getThemeGroupList();
        this.themeSearch.principalIdList = undefined;
        this.themeSearch.subjectGroupIdList = undefined;
        if (value == 1 || value == 2) {
          this.handleTrack('toggle_generate_image_type', {
            type: value == 1 ? 'img2img' : 'text2img',
          });
          await this.getThemeList();
        }
        return resolve();
      });
    },
    async onTimeChange(dates, dateStrings) {
      this.initErrInfo();
      this.checkTimeRange = [dates[0], dates[1]];
      this.searchTimeRangeText = `${moment(dateStrings[0]).format('MM-DD')} ~ ${moment(dateStrings[1]).format(
        'MM-DD'
      )}`;
      if (dateStrings[0] === moment().format('YYYY-MM-DD') && dateStrings[1] === moment().format('YYYY-MM-DD')) {
        this.searchTimeRangeText = '今日';
      }
      if (
        dateStrings[0] === moment().subtract(6, 'days').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '近七天';
      }
      if (
        dateStrings[0] === moment().startOf('week').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().endOf('week').format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '本周';
      }
      if (
        dateStrings[0] === moment().startOf('month').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().endOf('month').format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '本月';
      }
      if (!dateStrings[0]) {
        this.searchTimeRangeText = '不限时间';
      }
      this.showDropdown = false;
      this.openTimePanel = false;
      this.taskCheckId = '';
      this.imgCheckIdList = [];
      this.imgList = [];
      this.taskCurrent = 1;
      this.imgCurrent = 1;
      this.getPageTask();

      if (this.showAll) {
        // 查看全部逻辑
        this.loading = true;
        await this.pageAiImageOutput();
        this.loading = false;
      }
    },
    async onTaskTimeChange(dates, dateStrings) {
      this.taskForm.ctime = dateStrings;
    },
    // 查询车系列表
    async getSeriesList() {
      const { code, data, message } = await api
        .getXhsAiImageCarSeriesPromptList({
          page: 1,
          size: 999,
        })
        .finally(() => (this.carSeriesLoading = false));
      if (code === 200) {
        this.allCarListData = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async handleTranslate() {
      this.translateLoading = true;
      this.translateResult = undefined;
      const { data, code, message } = await api['getAiRewriteStory']({
        content: this.translateText,
        aiRewriteType: 'CN_TRANSLATE_EN',
      }).finally(() => (this.translateLoading = false));
      if (code == 200) {
        this.translateResult = data.rewriteContent;
      } else {
        this.$message.error(message);
      }
    },
    initErrInfo() {
      this.errorInfo = this.$options.data().errorInfo;
    },
    handleFillTranslate() {
      this.generateForm.prompt = this.generateForm.prompt
        ? this.generateForm.prompt + `${this.translateResult}, `
        : `${this.translateResult}, `;
      this.translateText = undefined;
      this.translateResult = undefined;
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    async getAiSetting() {
      const { code, data, message } = await api.getAiPicSetting();
      if (code === 200) {
        this.aiOption = data;
      } else {
        this.$message.error(message);
      }
    },
    //切换任务类型
    async onTaskChangeType(value) {
      return new Promise(async (resolve) => {
        this.taskActiveIndex = value;
        this.taskList = [];
        this.imgCurrent = 1;
        this.taskCheckId = '';
        this.imgCheckIdList = [];
        this.imgList = [];
        this.taskCurrent = 1;
        this.allTaskLoading = false;
        const hide = this.$message.loading('加载中...', 0);
        await this.runningAndLineTaskList();
        await this.getPageTask();
        hide();
        this.errorInfo = {
          taskId: undefined,
          showTips: false,
          showModal: false,
          total: 0,
          success: 0,
          imgCheckIdList: [],
          errImgList: [],
        };
        return resolve();
      });
    },
    // 搜索文生图主题
    async getThemeList() {
      const params = {
        publishStatus: 1,
        sortFieldList: [{ fieldName: 'sortNumber', orderType: 'desc' }],
        subjectTypeList: this.activeIndex == 2 ? ['TEXT2IMAGE_CAR'] : ['CAR_REPLACE_BACKGROUND'],
        principalIdList:
          this.activeIndex == 2 && this.themeSearch.principalIdList ? [this.themeSearch.principalIdList] : undefined,
        subjectGroupIdList: this.themeSearch.subjectGroupIdList ? [this.themeSearch.subjectGroupIdList] : undefined,
        page: 1,
        size: 9999,
      };
      this.themeLoading = true;
      const { code, data, message } = await themeApi
        .getAiImageSubjectList(params)
        .finally(() => (this.themeLoading = false));
      if (code === 200) {
        this.themeList = data.list;
        this.totalPage = data.totalPage;
        this.current = data.page;
      } else {
        this.$message.error(message);
      }
    },
    // 文生图分组列表
    async getThemeGroupList() {
      const taskType = this.activeIndex == 2 ? 'TEXT2IMAGE_CAR' : 'CAR_REPLACE_BACKGROUND';
      const { code, data, message } = await themeApi.getAiImageSubjectGroupList({ page: 1, size: 999, taskType });
      if (code === 200) {
        this.themeGroupList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getThemeBrandList() {
      const { code, data, message } = await themeApi.getAiImageSubjectBrandList();
      if (code === 200) {
        this.themeBrandList = data;
      } else {
        this.$message.error(message);
      }
    },
    handleGroupTagClick(id) {
      this.themeSearch.subjectGroupIdList = id;
      this.themeCheckId = undefined;
      this.getThemeList();
    },
    handleSearchChange() {
      this.themeCheckId = undefined;
      this.getThemeList();
    },
    getCarAngleText(text) {
      const a = this.angleList.find((v) => v.value === text);
      return a ? a.text : text;
    },
    filterOption(inputValue, option) {
      return option.componentOptions.propsData.label.indexOf(inputValue) > -1;
    },
    handleComfyOk() {
      this.tempComfyConfigList = _.cloneDeep(this.comfyConfigList);
      this.handleCloseComfy();
    },
    handleCloseComfy() {
      this.comfyConfigList = _.cloneDeep(this.tempComfyConfigList);
      this.showComfySetting = false;
    },
    // 获取comfyUI配置
    async getTypeConfig(taskType) {
      const { code, data, message } = await themeApi.getAiImageSubjectTypeConfig({ taskType });
      if (code === 200) {
        this.comfyConfigList = data && Object.keys(data).length ? data : {};
        this.tempComfyConfigList = _.cloneDeep(this.comfyConfigList);
      } else {
        this.$message.error(message);
      }
    },
    checkLimit() {
      return new Promise((resolve) => {
        let limited = false;
        const limitedRoleList = ['猛士运营'];
        let limitedName = '';
        this.$router.app.user.roles.forEach((v) => {
          if (limitedRoleList.indexOf(v.name) > -1) {
            limited = true;
            limitedName = v.name;
            return
          }
        });
        resolve({ limited, limitedName });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 120px);
  padding: 16px;
  margin: -24px -16px -40px;
  background: #f0f2f5;
}

// .left-center {
// min-height: 570px;
// }
.tag-line {
  display: flex;

  .tag-title {
    padding-top: 5px;
    width: 60px;
    color: #262626;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    color: #2d2d2d;
    align-items: center;

    .tags {
      padding: 5px 10px;
      margin: 0 10px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      background-color: #1890ff;
      color: #fff;
    }

    .tag-add {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .tag-input {
        width: 120px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .tag-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  .title {
    font-weight: bold;
  }
}
.theme-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 2%;
  margin: 5px 0 10px;
  max-height: calc(100vh - 480px);
  overflow-y: auto;
  .box {
    position: relative;
    padding-bottom: 30%;
    margin-bottom: 10px;
    width: 23.5%;
    height: 0;
    background-size: cover;
    background-position: center;
    margin-right: 1.5%;
    &:nth-child(4n) {
      margin-right: auto;
    }
    &.active-theme {
      border: 3px solid #1890ff;
    }
    &:last-child {
      margin-right: auto;
    }
    &:hover .icon-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &:hover .info-box {
      display: block;
    }
  }
  .theme {
    cursor: pointer;
  }
  .exclamation {
    position: absolute;
    top: 0;
    right: 0;
  }

  .info-box {
    display: none;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .title {
      padding: 0 5px;
      width: 100%;
      font-size: 12px;
      line-height: 1.2;
      color: #fff;
      background-color: #615f5f4d;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .icon-box {
      display: none;
      margin: 0 10% 8px;
    }
  }
}

.theme-list-pic-height {
  max-height: calc(100vh - 410px);
}

.icon {
  padding: 4px;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
}

.action-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-box {
  flex: 1;
  display: inline-block;
  .alert-box-content {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    position: relative;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 4px;
  }
  .info {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    color: #1890ff;
  }
  .light {
    padding: 0 5px;
    color: #1890ff;
    cursor: pointer;
  }
}
.show-all {
  margin-left: 20px;
  span {
    display: inline-block;
    margin-left: 4px;
    position: relative;
    bottom: -2px;
  }
}
.img-list {
  margin-top: 10px;
  min-height: 280px;
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -2%;
  &.height {
    max-height: 700px;
  }
  .box {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 30%;
    width: 23%;
    margin-left: 2%;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    &:hover {
      .delete {
        display: block;
      }
    }
    &:last-child {
      margin-right: auto;
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
    }
    .exclamation {
      position: absolute;
      top: 0;
      right: 0;
    }

    .txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      white-space: nowrap;
      cursor: pointer;
      &.red {
        color: red;
      }
    }
    .delete {
      position: absolute;
      bottom: 10px;
      left: 50%;
      display: none;
      transform: translateX(-50%);
    }
    .exclamation {
      position: absolute;
      top: 1px;
      right: 35px;
    }
  }
}
.checkbox {
  position: absolute;
  top: 1px;
  right: 0;
  line-height: 0;
  /deep/ .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  /deep/ .ant-checkbox-inner::after {
    width: 8px;
    height: 14px;
  }
}
.preview-box {
  position: relative;
  .preview {
    width: 100%;
  }
}
.setting-title {
  font-size: 20px;
  font-weight: bold;
  margin: 25px 0 10px;
}
.setting-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item {
    margin-bottom: 10px;
    width: 48%;
  }
}
.w-100 {
  width: 100%;
}
.no-result {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.img-box {
  display: flex;
  &.default {
    pointer-events: none;
  }
  .img {
    margin-right: 20px;
    width: 102px;
    height: 102px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
  }
  .content {
    flex: 1;
    display: flex;
    align-items: center;
    .txt {
      cursor: pointer;
      padding-left: 16px;
      color: #999;
    }
  }
}

.task-box-top {
  display: flex;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  height: 36px;
  line-height: 36px;
  padding-left: 45px;

  .task-time {
    color: #1990fe;
    cursor: pointer;
  }
}

.task-box {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
  background: #fff;
  .title {
    margin-right: 10px;
    width: 20px;
    color: #999;
    text-align: center;
  }
  .content {
    flex: 1;
    overflow: hidden;
  }
  &-1 .task-list-box {
    max-height: 150px;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 0px;
    border-radius: 0px;
  }
  .task-list-box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    .box {
      position: relative;
      margin: 5px 10px 5px 0;
      height: 120px;
      width: 90px;
      flex-shrink: 0;
      background-color: rgba(0, 0, 0, 0.05);
      &.active-task {
        border: 3px solid #0084ff;
      }
      &:last-child {
        margin-right: auto;
      }
      &:hover {
        .icon-content {
          display: flex;
        }
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        &.success-task {
          cursor: pointer;
        }
        &.no-success-task {
          cursor: not-allowed;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.35);
        }
      }
      .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        white-space: nowrap;
        cursor: pointer;
        &.red {
          color: red;
        }
      }
      .icon-content {
        position: absolute;
        bottom: 10px;
        left: 50%;
        display: none;
        transform: translateX(-50%);
      }
      .delete {
        position: absolute;
        bottom: 10px;
        left: 50%;
        display: none;
        transform: translateX(-50%);
      }
      .eye {
        position: absolute;
        bottom: 10px;
        left: 40%;
        display: none;
        transform: translateX(-40%);
      }
      .exclamation {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.bottom-line {
  display: flex;
  margin-top: 10px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin: 0 5px 5px 0;
    padding: 0 5px;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #80c2ff;
    user-select: none;
  }
}

.translate_wrap {
  position: absolute;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -50px;
  right: -266px;
  width: 245px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 5px 4px 18px 0px #212b365c;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 50px;
    left: -20px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 20px solid #fff;
    border-left: 10px solid transparent;
    border-bottom: 15px solid transparent;
  }

  .wrap_top {
    display: flex;
    width: 100%;
    margin: 0px 10px 10px;
    align-items: center;
    justify-content: space-between;
  }

  .trans_res {
    padding-left: 15px;
    width: 100px;
    text-align: center;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.img-detail {
  position: absolute;
  bottom: 0;
  padding: 0 5px;
  width: 100%;
  height: 48px;
  color: #aaaaaa;
  background-color: #00000090;
  text-align: end;
}

.tips_row {
  display: flex;
  background-color: #fffbe6;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

::v-deep .ant-form-item {
  margin-bottom: 3px;
}

.group_list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 0;

  .group_tag {
    margin-bottom: 5px;
    cursor: pointer;
  }
}
</style>

<style>
.dropdown-custom {
  opacity: 0;
}
</style>
